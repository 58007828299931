import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/700-italic.css';

const initTrustPilotWidgets = () => {
	const trustPilotWidgets = document.querySelectorAll('.js-trustpilot-widget');

	if (trustPilotWidgets.length > 0 && window.Trustpilot) {
		trustPilotWidgets.forEach((trustbox) => {
			window.Trustpilot.loadFromElement(trustbox);
		});
	}
};

const initLiveChat = () => {
	const now = new Date();
	const etNow = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
	const day = etNow.getDay();
	const hour = etNow.getHours();

	// LiveChat during business hours Monday to Friday 8am to 8pm
	if (day >= 1 && day <= 5 && hour >= 8 && hour < 20) {
		window.__lc = window.__lc || {};
		window.__lc.license = 11976798;
		window.__lc.integration_name = 'manual_channels';
		window.__lc.product_name = 'livechat';
		window.__lc.chat_between_groups = false;

		const script = document.createElement('script');
		script.src = 'https://cdn.livechatinc.com/tracking.js';
		script.async = true;
		script.type = 'text/javascript';
		document.head.appendChild(script);
	}
};

export const onRouteUpdate = () => {
	initTrustPilotWidgets();
	initLiveChat();
};

export const onInitialClientRender = () => {
	initTrustPilotWidgets();
	initLiveChat();
};
